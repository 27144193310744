import React, { useEffect } from "react";
import { Company, fetchCompanies, moveCompanyDomains } from "../api/companies";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import { fetchAuthSession } from "@aws-amplify/auth";

export function MoveCompanyDomainsModal(props: {
  open: boolean;
  onClose: () => void;
  domainsGridApi: any;
}) {
  const [newCompanyName, setNewCompanyName] = React.useState<string>("");
  const [companyOptions, setCompanyOptions] = React.useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<Company>();
  const [fetchingCompanies, setFetchingCompanies] =
    React.useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFetchingCompanies(true);
    const delayedDebounceFn = setTimeout(() => {
      fetchAuthSession().then((credentials) => {
        fetchCompanies(
          credentials.tokens,
          0,
          newCompanyName,
          "",
          "",
          null
        ).then((response) => {
          setCompanyOptions(response.companies);
          setFetchingCompanies(false);
        });
      });
    }, 2000);
    return () => clearTimeout(delayedDebounceFn);
  }, [newCompanyName]);

  const handleCompanyExtraction = () => {
    if (
      props.domainsGridApi &&
      props.domainsGridApi.getSelectedRows().length > 0
    ) {
      const affectedIds = props.domainsGridApi
        .getSelectedRows()
        .map((node: { id: any }) => node.id);
      if (affectedIds && affectedIds.length > 0 && selectedCompany) {
        fetchAuthSession().then((credentials) => {
          moveCompanyDomains(
            credentials.tokens,
            selectedCompany.id,
            affectedIds
          ).then((response) =>
            enqueueSnackbar("Domains Moved", { variant: "success" })
          );
        });
      }
    }
    props.onClose();
  };

  return (
    <Modal
      open={props.open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth={"sm"}>
        <Card>
          <CardHeader
            title={"Move Domains"}
            titleTypographyProps={{ variant: "h4" }}
          />
          <Divider />
          <CardContent>
            <Autocomplete
              style={{ marginTop: "10px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Start typing a company name"
                  onChange={(e) => setNewCompanyName(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {fetchingCompanies ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              getOptionLabel={(option) => `${option.name} (${option.id})`}
              options={companyOptions}
              onChange={(event, value) => {
                if (value) {
                  setSelectedCompany(value);
                }
              }}
            />
            {props.domainsGridApi &&
              // @ts-ignore
              props.domainsGridApi.getSelectedNodes().map((node) => {
                return (
                  <div
                    key={node.data.id}
                  >{`${node.data.sub_name} ---> ${node.data.sub_domain}.${node.data.domain_name}.${node.data.domain_extension}`}</div>
                );
              })}
          </CardContent>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ margin: "2px", padding: "0em 0.5em" }}
              onClick={() => handleCompanyExtraction()}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ margin: "2px", padding: "0em 0.5em" }}
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
