import React, { useEffect, useState } from "react";
import { theme } from "./theme/theme";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";
import { AppBar, Button, Toolbar } from "@mui/material";

import { CirromStyles } from "./components/CirromStyles";
import { Releases } from "./components/Releases";
import AWS from "aws-sdk";
import { SnackbarProvider } from "notistack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContext, TabPanel } from "@mui/lab";
import { Companies } from "./components/Companies";
import { Reports } from "./components/Reports";
import "./App.css";
import { Dashboards } from "./components/Dashboards";
import { CompaniesMerge } from "./components/CompaniesMerge";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession, signOut } from "@aws-amplify/auth";
import { CirromClient, getAvailableClients } from "./api/releases";

// @ts-ignore
function App() {
  const [selectedTab, setSelectedTab] = useState<string>("1");
  const [clients, setClients] = useState<CirromClient[]>([]);
  useEffect(() => {
    let isMounted = true;
    AWS.config.update({ region: "us-east-2" });

    fetchAuthSession().then((credentials) => {
      getAvailableClients(credentials.tokens).then((response) => {
        if (isMounted) {
          setClients(response);
        }
      });
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={theme}>
            <CirromStyles />
            <AppBar style={{ padding: "5px 0px" }}>
              <Toolbar variant="dense">
                <img
                  alt="LogoSm"
                  src="/Cirrom-C.webp"
                  style={{ height: "50px" }}
                />

                <Box style={{ flexGrow: 1 }} />
                <Button onClick={() => signOut()}>Logout</Button>
              </Toolbar>
            </AppBar>
            <TabContext value={selectedTab}>
              <Tabs
                variant={"fullWidth"}
                value={selectedTab}
                onChange={(event, val) => setSelectedTab(val)}
              >
                <Tab label={"Reports"} value={"1"} />
                <Tab label={"Releases"} value={"2"} />
                <Tab label={"Company Map"} value={"3"} />
                <Tab label={"Dashboards"} value={"4"} />
                <Tab label={"Companies Merge"} value={"5"} />
              </Tabs>

              <TabPanel value={"1"}>
                <Reports />
              </TabPanel>
              <TabPanel value={"2"}>
                <Releases />
              </TabPanel>
              <TabPanel value={"3"}>
                <Companies />
              </TabPanel>
              <TabPanel value={"4"}>
                <Dashboards clients={clients} />
              </TabPanel>
              <TabPanel value={"5"}>
                <CompaniesMerge />
              </TabPanel>
            </TabContext>
          </ThemeProvider>
        </SnackbarProvider>
      </StyledEngineProvider>
    </>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
