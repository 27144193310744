import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import {
  CentralEmailFormatResponse,
  Company,
  CompanyDomains,
  fetchCentralEmailFormatForCompany,
  fetchCompanies,
  fetchDomainsForCompany,
  markCompanyAsReviewed,
  removeDomainsFromModel,
  markCompanyAsModelNotViable,
  markDomainsAsTypo,
} from "../api/companies";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Typography from "@mui/material/Typography";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ExtractCompanyDomains } from "./ExtractCompanyDomainsModal";
import { MoveCompanyDomainsModal } from "./MoveCompanyDomainsModal";
import { EditCompanyFormatModal } from "./EditCompanyFormatModal";
import { useSnackbar } from "notistack";
import {
  CompanyMetadata,
  fetchMetadataForCompany,
  fetchSICCodesForCompany,
  SICCode,
} from "../api/metadata";
import { UpdateCompanySIC } from "./UpdateCompanySIC";
import { ColumnState } from "ag-grid-community";
import { MultiCompanyMerge } from "./MultiCompanyMerge";
import { fetchAuthSession } from "@aws-amplify/auth";

export function Companies() {
  const [fetchedCompanies, setFetchCompanies] = React.useState<boolean>(false);

  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [totalCompanies, setTotalCompanies] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(0);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<number>();
  const [companyDomains, setCompanyDomains] = React.useState<CompanyDomains[]>(
    []
  );

  const [sortOrder, setSortOrder] = React.useState<string | null>(null);

  const [domainsGridApi, setDomainsGridApi] = React.useState<any>();
  const [companiesGridApi, setCompaniesGridApi] = React.useState<any>();
  const [formatsGridApi, setFormatsGridApi] = React.useState<any>();

  const [companySearchTerm, setCompanySearchTerm] = React.useState<string>("");
  const [domainSearchTerm, setDomainSearchTerm] = React.useState<string>("");
  const [clientSearchTerm, setClientSearchTerm] = React.useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const [isCompanyExtractionOpen, setIsCompanyExtractionOpen] =
    React.useState<boolean>(false);

  const [isMultiMergeOpen, setIsMultiMergeOpen] =
    React.useState<boolean>(false);

  const [isCompanyMoveOpen, setIsCompanyMoveOpen] =
    React.useState<boolean>(false);

  const [isFormatModalOpen, setIsFormatModalOpen] =
    React.useState<boolean>(false);

  const [selectedEmailFormat, setSelectedEmailFormat] =
    React.useState<CentralEmailFormatResponse>();

  const [companyFormats, setCompanyFormats] = React.useState<
    CentralEmailFormatResponse[]
  >([]);

  const [companyMetadata, setCompanyMetadata] = React.useState<
    CompanyMetadata[]
  >([]);

  const [sicCodes, setSicCodes] = React.useState<SICCode[]>([]);
  const [isSICModalOpen, setSICModalOpen] = React.useState<boolean>(false);

  const companiesColumns = [
    {
      field: "id",
      headerName: "ID",
      resizable: true,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Name",
      resizable: true,
      sortable: true,
    },
    {
      field: "instance_count",
      headerName: "Instance Count",
      resizable: true,
      sortable: true,
    },
    {
      field: "ticket_count",
      headerName: "Ticket Count",
      resizable: true,
      sortable: true,
    },
    {
      field: "manually_reviewed",
      headerName: "Manually Reviewed",
      resizable: true,
      sortable: true,
    },
    {
      field: "is_inactive",
      headerName: "Disabled",
      resizable: true,
      sortable: true,
    },
    {
      field: "model_not_viable",
      headerName: "Model Not Viable",
      resizable: true,
      sortable: true,
    },
  ];

  const domainColumns = [
    {
      field: "id",
      headerName: "ID",
      resizable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      sortable: true,
    },
    {
      field: "sub_domain",
      headerName: "Sub Domain",
      resizable: true,
      sortable: true,
    },
    {
      field: "domain_name",
      headerName: "Domain",
      resizable: true,
      sortable: true,
    },
    {
      field: "domain_extension",
      headerName: "EXT",
      resizable: true,
      sortable: true,
    },
    {
      field: "end_url",
      headerName: "End URL",
      resizable: true,
      sortable: true,
    },
    {
      field: "sub_name",
      headerName: "Sub Name",
      resizable: true,
      sortable: true,
    },
    {
      field: "instance_count",
      headerName: "Instance Count",
      resizable: true,
      sortable: true,
    },
    {
      field: "mx_status",
      headerName: "MX Status",
      resizable: true,
      sortable: true,
    },

    {
      field: "whois_org_name",
      headerName: "Whois Org",
      resizable: true,
      sortable: true,
    },
    {
      field: "whois_registrar",
      headerName: "Whois Registrar",
      resizable: true,
      sortable: true,
    },
    {
      field: "whois_last_updated_time",
      headerName: "Whois Time",
      resizable: true,
      sortable: true,
    },

    {
      field: "copyright_footer",
      headerName: "Copyright footer",
      resizable: true,
      sortable: true,
    },

    {
      field: "url_header",
      headerName: "URL Header",
      resizable: true,
      sortable: true,
    },

    {
      field: "url_last_checked_time",
      headerName: "URL Checked Time",
      resizable: true,
      sortable: true,
    },
    { field: "used_in_model_building", headerName: "Usable" },
  ];

  const formatColumns = [
    {
      field: "id",
      headerName: "ID",
      resizable: true,
    },
    {
      field: "source",
      headerName: "Source",
      resizable: true,
    },
    {
      field: "active",
      headerName: "Active",
      resizable: true,
    },
    {
      field: "model",
      headerName: "Model",
      resizable: true,
      cellStyle: { whiteSpace: "pre-wrap" },

      cellRenderer: (params: any) => {
        return (
          <Typography variant="body2">
            {JSON.stringify(JSON.parse(params.data.model), null, 2)}
          </Typography>
        );
      },
    },
  ];

  const metadataColumns = [
    {
      field: "id",
      headerName: "ID",
      resizable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },

    {
      field: "external_company_identifier",
      headerName: "External ID",
      resizable: true,
    },
    {
      field: "external_company_name",
      headerName: "External Name",
      resizable: true,
    },
    {
      field: "date_of_creation",
      headerName: "Date Created",
      resizable: true,
    },
    {
      field: "address_line",
      headerName: "Address line",
      resizable: true,
    },
    {
      field: "postal_code",
      headerName: "Postal Code",
      resizable: true,
    },
    {
      field: "city",
      headerName: "City",
      resizable: true,
    },
    {
      field: "country",
      headerName: "Country",
      resizable: true,
    },
  ];

  const SICColumns = [
    {
      field: "id",
      headerName: "ID",
      resizable: true,
    },
    {
      field: "sic_code",
      headerName: "SIC Code",
      resizable: true,
    },
    {
      field: "sic_code_description",
      headerName: "Description",
      resizable: true,
    },
  ];

  useEffect(() => {
    if (companiesGridApi) {
      companiesGridApi.showLoadingOverlay();
    }
    const delayedDebounceFn = setTimeout(() => {
      fetchAuthSession().then((credentials) => {
        fetchCompanies(
          credentials.tokens,
          pageNumber,
          companySearchTerm,
          domainSearchTerm,
          clientSearchTerm,
          sortOrder
        ).then((response) => {
          setCompanies(response.companies);
          setTotalCompanies(response.total);
          setFetchCompanies(true);
          if (companiesGridApi) {
            companiesGridApi.hideOverlay();
          }
        });
      });
    }, 2000);

    return () => clearTimeout(delayedDebounceFn);
  }, [
    pageNumber,
    companySearchTerm,
    domainSearchTerm,
    clientSearchTerm,
    sortOrder,
  ]);

  useEffect(() => {
    if (selectedCompanyId) {
      domainsGridApi.showLoadingOverlay();
      formatsGridApi.showLoadingOverlay();
      fetchAuthSession().then((credentials) => {
        fetchDomainsForCompany(credentials.tokens, selectedCompanyId).then(
          (response) => {
            setCompanyDomains(response);
            domainsGridApi.hideOverlay();
          }
        );
        fetchCentralEmailFormatForCompany(
          credentials.tokens,
          selectedCompanyId
        ).then((response) => {
          setCompanyFormats(response);
          formatsGridApi.hideOverlay();
        });
        fetchMetadataForCompany(credentials.tokens, selectedCompanyId).then(
          (response) => setCompanyMetadata(response)
        );
        fetchSICCodesForCompany(credentials.tokens, selectedCompanyId).then(
          (response) => setSicCodes(response)
        );
      });
    }
  }, [selectedCompanyId]);

  return (
    <Card>
      <CardHeader
        title={"Companies"}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent style={{ height: "1350px" }}>
        <Divider />
        {!fetchedCompanies && <LinearProgress variant={"indeterminate"} />}
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            gap: "20px",
            marginBottom: "10px",
          }}
        >
          <TextField
            margin={"dense"}
            size={"small"}
            variant={"standard"}
            label={"Company Search"}
            onChange={(event) => {
              setCompanySearchTerm(event.target.value);
            }}
          />
          <TextField
            margin={"dense"}
            size={"small"}
            variant={"standard"}
            label={"Domain Search"}
            onChange={(event) => {
              setDomainSearchTerm(event.target.value);
            }}
          />

          <TextField
            margin={"dense"}
            size={"small"}
            variant={"standard"}
            label={"Client Search"}
            onChange={(event) => {
              setClientSearchTerm(event.target.value);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              margin: "2px",
              padding: "0em 0.5em",
              textTransform: "capitalize",
              height: "30px",
              width: "220px",
              whiteSpace: "nowrap",
            }}
            onClick={() => {
              if (selectedCompanyId) {
                fetchAuthSession().then((credentials) => {
                  markCompanyAsReviewed(
                    credentials.tokens,
                    selectedCompanyId
                  ).then((response) => {
                    enqueueSnackbar("Company marked as reviewed", {
                      variant: "success",
                    });
                  });
                });
              }
            }}
          >
            Mark as Reviewed
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              margin: "2px",
              padding: "0em 0.5em",
              textTransform: "capitalize",
              height: "30px",
              width: "300px",
              whiteSpace: "nowrap",
            }}
            onClick={() => {
              if (selectedCompanyId) {
                fetchAuthSession().then((credentials) => {
                  markCompanyAsModelNotViable(
                    credentials.tokens,
                    selectedCompanyId
                  ).then((response) => {
                    enqueueSnackbar("Company marked as model not viable", {
                      variant: "success",
                    });
                  });
                });
              }
            }}
          >
            Mark as Model Not Viable
          </Button>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                margin: "2px",
                padding: "0em 0.5em",
                textTransform: "capitalize",
                height: "30px",
              }}
              onClick={() => {
                const domainIds = domainsGridApi
                  .getSelectedRows()
                  .map((row: { id: number }) => row.id);
                if (domainIds.length > 0) {
                  fetchAuthSession().then((credentials) => {
                    markDomainsAsTypo(credentials.tokens, domainIds).then(
                      (response) => {
                        enqueueSnackbar("Domains Marked as Typo", {
                          variant: "success",
                        });
                      }
                    );
                  });
                }
              }}
            >
              Mark as Typo
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                margin: "2px",
                padding: "0em 0.5em",
                textTransform: "capitalize",
                height: "30px",
              }}
              onClick={() => {
                const domainIds = domainsGridApi
                  .getSelectedRows()
                  .map((row: { id: number }) => row.id);
                if (domainIds.length > 0) {
                  fetchAuthSession().then((credentials) => {
                    removeDomainsFromModel(credentials.tokens, domainIds).then(
                      (response) => {
                        enqueueSnackbar("Domains Removed", {
                          variant: "success",
                        });
                      }
                    );
                  });
                }
              }}
            >
              Disable Domains
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                margin: "2px",
                padding: "0em 0.5em",
                textTransform: "capitalize",
                height: "30px",
              }}
              onClick={() => {
                if (
                  domainsGridApi &&
                  domainsGridApi.getSelectedRows().length > 0
                ) {
                  setIsCompanyExtractionOpen(true);
                }
              }}
            >
              Extract Domains
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                margin: "2px",
                padding: "0em 0.5em",
                textTransform: "capitalize",
                height: "30px",
              }}
              onClick={() => {
                if (
                  domainsGridApi &&
                  domainsGridApi.getSelectedRows().length > 0
                ) {
                  setIsCompanyMoveOpen(true);
                }
              }}
            >
              Move Domains
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                margin: "2px",
                padding: "0em 0.5em",
                textTransform: "capitalize",
                height: "30px",
              }}
              onClick={() => {
                setIsMultiMergeOpen(true);
              }}
            >
              Multi Merge!
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="ag-theme-alpine"
            style={{ height: 1200, width: "39%" }}
          >
            <AgGridReact
              rowData={companies}
              columnDefs={companiesColumns}
              onSelectionChanged={(rowEvent) =>
                setSelectedCompanyId(rowEvent.api.getSelectedRows()[0].id)
              }
              onSortChanged={(event) => {
                let localSortOrder = null;
                if (event.columnApi.getColumnState()) {
                  event.columnApi.getColumnState().forEach((column) => {
                    if (column.colId === "ticket_count") {
                      localSortOrder = "ticket_count";
                    }
                  });
                  if (localSortOrder !== sortOrder) {
                    setSortOrder(localSortOrder);
                  }
                }
              }}
              rowClassRules={{
                "row-fail": (params) => params?.data?.model_not_viable ?? false,
                "row-success": (params) =>
                  params?.data?.manually_reviewed ?? false,
              }}
              rowSelection={"single"}
              onGridReady={(ev) => setCompaniesGridApi(ev.api)}
              onRowDataUpdated={(ev) => ev.columnApi.autoSizeAllColumns()}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant={"caption"}>
                Total:{new Intl.NumberFormat("en-US").format(totalCompanies)}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}
                >
                  <NavigateBefore />
                </IconButton>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "10px",
                  }}
                >
                  <Typography variant={"caption"}>Page:</Typography>
                  <TextField
                    margin={"dense"}
                    size={"small"}
                    variant={"standard"}
                    value={pageNumber + 1}
                    onChange={(event) => {
                      setPageNumber(parseInt(event.target.value) - 1);
                    }}
                    style={{ width: "20px" }}
                    inputProps={{ style: { fontSize: "0.75rem" } }}
                  />
                  <Typography variant={"caption"}>
                    of {(totalCompanies / 250).toFixed(0)}
                  </Typography>
                </div>

                <IconButton onClick={() => setPageNumber(pageNumber + 1)}>
                  <NavigateNext />
                </IconButton>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "100%",
              width: "59%",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            <div className="ag-theme-alpine" style={{ height: 295 }}>
              <AgGridReact
                rowData={companyDomains}
                columnDefs={domainColumns}
                rowSelection={"multiple"}
                onGridReady={(ev) => setDomainsGridApi(ev.api)}
                rowClassRules={{
                  "row-fail": (params) => {
                    return (
                      (params?.data?.mx_status === "No MX Record Found" ??
                        false) ||
                      (params?.data?.used_in_model_building ?? false)
                    );
                  },
                  "row-success": (params) =>
                    (params?.data?.mx_status === "Valid" ?? false) &&
                    (params?.data?.used_in_model_building ?? false),
                }}
                onRowDataUpdated={(ev) => {
                  ev.columnApi.autoSizeAllColumns();
                  const defaultSortModel = [
                    {
                      colId: "mx_status",
                      sort: "desc",
                      sortIndex: 0,
                    } as ColumnState,
                    {
                      colId: "used_in_model_building",
                      sort: "desc",
                      sortIndex: 0,
                    } as ColumnState,
                    {
                      colId: "end_url",
                      sort: "desc",
                      sortIndex: 1,
                    } as ColumnState,
                  ];
                  ev.columnApi.applyColumnState({ state: defaultSortModel });
                }}
                multiSortKey={"ctrl"}
                onCellDoubleClicked={(event) => {
                  if (event.node.data && event.value) {
                    if (
                      event.colDef.field === "domain_name" &&
                      event.node.data["domain_extension"]
                    ) {
                      window.open(
                        `http://${event.value}.${event.node.data["domain_extension"]}`
                      );
                    }
                    if (event.colDef.field === "end_url") {
                      window.open(event.value);
                    }
                  }
                }}
              />
            </div>
            <div className="ag-theme-alpine" style={{ height: 295 }}>
              <AgGridReact
                rowData={companyFormats}
                columnDefs={formatColumns}
                onGridReady={(ev) => setFormatsGridApi(ev.api)}
                onRowDataUpdated={(ev) => ev.columnApi.autoSizeAllColumns()}
                getRowHeight={(params) => {
                  return (
                    JSON.stringify(
                      JSON.parse(params.data?.model ?? "{"),
                      null,
                      2
                    ).split(/\r\n|\r|\n/).length * 20
                  );
                }}
                onRowDoubleClicked={(ev) => {
                  setSelectedEmailFormat(
                    ev.node.data as CentralEmailFormatResponse
                  );
                  setIsFormatModalOpen(true);
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  margin: "2px",
                  padding: "0em 0.5em",
                  textTransform: "capitalize",
                  height: "30px",
                  width: "220px",
                  whiteSpace: "nowrap",
                }}
                onClick={() => setSICModalOpen(true)}
              >
                Update SIC
              </Button>
            </div>

            <div className="ag-theme-alpine" style={{ height: 295 }}>
              <AgGridReact
                rowData={companyMetadata}
                columnDefs={metadataColumns}
                rowSelection={"multiple"}
                onRowDataUpdated={(ev) => ev.columnApi.autoSizeAllColumns()}
              />
            </div>
            <div className="ag-theme-alpine" style={{ height: 295 }}>
              <AgGridReact
                rowData={sicCodes}
                columnDefs={SICColumns}
                onRowDataUpdated={(ev) => ev.columnApi.autoSizeAllColumns()}
              />
            </div>
          </div>
        </div>
        <ExtractCompanyDomains
          open={isCompanyExtractionOpen}
          selectedCompanyId={selectedCompanyId as number}
          domainsGridApi={domainsGridApi}
          onClose={() => setIsCompanyExtractionOpen(false)}
        />
        <MultiCompanyMerge
          open={isMultiMergeOpen}
          onClose={() => setIsMultiMergeOpen(false)}
        />
        <MoveCompanyDomainsModal
          open={isCompanyMoveOpen}
          onClose={() => setIsCompanyMoveOpen(false)}
          domainsGridApi={domainsGridApi}
        />
        {selectedEmailFormat && (
          <EditCompanyFormatModal
            open={isFormatModalOpen}
            onClose={() => setIsFormatModalOpen(false)}
            selectedFormat={selectedEmailFormat}
          />
        )}
        {selectedCompanyId && (
          <UpdateCompanySIC
            selectedCompanyId={selectedCompanyId}
            open={isSICModalOpen}
            onClose={() => setSICModalOpen(false)}
          />
        )}
      </CardContent>
    </Card>
  );
}
