import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { useSnackbar } from "notistack";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  getCompanyNameClashes,
  mergeMultipleCompanies,
} from "../api/companies";
import { fetchAuthSession } from "@aws-amplify/auth";

export function CompaniesMerge() {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchAuthSession().then((credentials) => {
      getCompanyNameClashes(credentials.tokens).then((response) => {
        setRows(response);
      });
    });
  }, []);

  const requiredColumns = [
    {
      field: "do_action",
      headerName: "Do Action",
      resizable: true,
      cellRenderer: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant={"contained"}
              style={{ width: "20px", height: "20px" }}
              color={
                (params.data.parent || "").toString().split(",").length !== 1
                  ? "error"
                  : "primary"
              }
              onClick={() => {
                params.node.setDataValue("scenario", "Merged");
                const targetCompanyId = params.data.parent.toString();
                const subordinates = params.data.children;
                fetchAuthSession().then((credentials) => {
                  mergeMultipleCompanies(
                    credentials.tokens,
                    targetCompanyId,
                    subordinates.split(",")
                  )
                    .then((response) =>
                      enqueueSnackbar("Multi companies merged", {
                        variant: "success",
                      })
                    )
                    .catch((err) =>
                      enqueueSnackbar("Failed", { variant: "warning" })
                    );
                });
              }}
            >
              Merge
            </Button>
            <Button
              variant={"contained"}
              style={{ width: "20px", height: "20px" }}
              onClick={() => {
                params.node.setDataValue("scenario", "Ignored");
              }}
            >
              Ignore
            </Button>
          </div>
        );
      },
    },
    {
      field: "scenario",
      headerName: "scenario",
      resizable: true,
      cellRenderer: (params: any) => {
        return (
          <Typography variant={"caption"}>{params.data.scenario}</Typography>
        );
      },
    },

    {
      field: "w_model",
      headerName: "w_model",
      resizable: true,
      editable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return (
          <>
            {(params.data.w_model || "").split("),").map((it: any) => (
              <>
                {it} <br />
              </>
            ))}
          </>
        );
      },
    },
    {
      field: "wo_model",
      headerName: "wo_model",
      resizable: true,
      editable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: any) => {
        return (
          <>
            {(params.data.wo_model || "").split("),").map((it: any) => (
              <>
                {it} <br />
              </>
            ))}
          </>
        );
      },
    },
    {
      field: "parent",
      headerName: "parent",
      resizable: true,
      editable: true,
    },
    {
      field: "children",
      headerName: "children",
      resizable: true,
      editable: true,
    },
  ];

  return (
    <Card>
      <CardHeader
        title={"Companies merge"}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent style={{ height: "1350px" }}>
        <Divider />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            className="ag-theme-alpine"
            style={{ height: 1200, width: "99%" }}
          >
            <AgGridReact
              rowData={rows}
              columnDefs={requiredColumns}
              rowClassRules={{
                //@ts-ignore
                "row-success": (params) => params?.data?.scenario === "Merged",
                //@ts-ignore
                "row-fail": (params) => params?.data?.scenario === "Ignored",
              }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
