import React, { useEffect } from "react";

import { CentralEmailFormatResponse, editEmailFormat } from "../api/companies";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import { fetchAuthSession } from "@aws-amplify/auth";

export function EditCompanyFormatModal(props: {
  open: boolean;
  onClose: () => void;
  selectedFormat: CentralEmailFormatResponse;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const handleFormatEdit = () => {
    const parsedGeneralOptions = JSON.parse(generalOptions);
    const parsedCountryOptions = JSON.parse(countryOptions);

    fetchAuthSession().then((credentials) => {
      editEmailFormat(
        credentials.tokens,
        props.selectedFormat.id,
        JSON.stringify({
          ...selectedFormatModel,
          general: parsedGeneralOptions,
          country_options: parsedCountryOptions,
        })
      ).then((response) => {
        props.onClose();
        enqueueSnackbar("Email format updated.", {
          variant: "success",
        });
      });
    });
  };

  const [selectedFormatModel, setSelectedFormatModel] = React.useState<any>();

  const [generalOptions, setGeneralOptions] = React.useState<any>();

  const [generalOptionsInErrorState, setGeneralOptionsInErrorState] =
    React.useState<boolean>(false);

  const [countryOptions, setCountryOptions] = React.useState<any>();

  const [countryOptionsInErrorState, setCountryOptionsInErrorState] =
    React.useState<boolean>(false);

  useEffect(() => {
    const parsedModel = JSON.parse(props.selectedFormat.model);
    setSelectedFormatModel(parsedModel);
    setCountryOptions(JSON.stringify(parsedModel.country_options, null, 2));
    setGeneralOptions(JSON.stringify(parsedModel.general, null, 2));
  }, [props.selectedFormat]);

  useEffect(() => {
    try {
      JSON.parse(generalOptions);
      setGeneralOptionsInErrorState(false);
    } catch (e) {
      setGeneralOptionsInErrorState(true);
    }
  }, [generalOptions]);

  useEffect(() => {
    try {
      JSON.parse(countryOptions);
      setCountryOptionsInErrorState(false);
    } catch (e) {
      setCountryOptionsInErrorState(true);
    }
  }, [countryOptions]);

  return (
    <Modal
      open={props.open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth={"md"}>
        {selectedFormatModel && (
          <Card>
            <CardHeader
              title={`Edit Company Format for ${selectedFormatModel.company_id} `}
              subheader={`Submitted at: ${props.selectedFormat.submit_time} by ${props.selectedFormat.source} `}
              titleTypographyProps={{ variant: "h4" }}
            />
            <Divider />
            <CardContent
              style={{
                marginTop: "10px",
                height: "75vh",
                overflow: "scroll",
              }}
            >
              <Typography variant={"h6"}>General Options</Typography>
              {generalOptions && (
                <TextField
                  margin={"dense"}
                  size={"small"}
                  multiline={true}
                  value={generalOptions}
                  fullWidth={true}
                  inputProps={{ style: { fontSize: 12 } }}
                  maxRows={15}
                  onChange={(e) => {
                    setGeneralOptions(e.target.value);
                  }}
                  error={generalOptionsInErrorState}
                />
              )}
              <Typography variant={"h6"}>Country Specific Options</Typography>
              {countryOptions && (
                <TextField
                  margin={"dense"}
                  size={"small"}
                  multiline={true}
                  value={countryOptions}
                  fullWidth={true}
                  inputProps={{ style: { fontSize: 12 } }}
                  maxRows={15}
                  onChange={(e) => {
                    setCountryOptions(e.target.value);
                  }}
                  error={countryOptionsInErrorState}
                />
              )}
            </CardContent>
            <CardContent
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: "2px", padding: "0em 0.5em" }}
                onClick={() => handleFormatEdit()}
              >
                Ok
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ margin: "2px", padding: "0em 0.5em" }}
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </CardContent>
          </Card>
        )}
      </Container>
    </Modal>
  );
}
