import axios from "axios";
import { AuthTokens } from "@aws-amplify/auth";
export interface CompanyMetadata {
  id: number;
  company_id: number;
  external_company_identifier: number;
  external_company_name: number;
  date_of_creation: Date;
  address_line: string;
  postal_code: string;
  city: string;
  country: string;
  last_update_time: Date;
}

export interface SICCode {
  id: number;
  company_metadata_id: number;
  sic_code: number;
  sic_code_description: string;
  last_update_time: Date;
}

export const fetchMetadataForCompany = async (
  credentials: AuthTokens | undefined,
  companyId: number
): Promise<CompanyMetadata[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_company_metadata`,
    { company_id: companyId },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const fetchSICCodesForCompany = async (
  credentials: AuthTokens | undefined,
  companyId: number
): Promise<SICCode[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_company_sic_codes`,
    { company_id: companyId },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const updateCompanySICInformation = async (
  credentials: AuthTokens | undefined,
  companyId: number,
  externalCompanyIdentifier: string
): Promise<void> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/update_company_metadata`,
    { company_id: companyId, external_id: externalCompanyIdentifier },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};
