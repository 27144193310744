import { Typography } from "@mui/material";

export function CirromLogo() {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
      role={"button"}
    >
      <img
        alt="Logo"
        src="/Cirrom-C.webp"
        style={{
          height: "50px",
          width: "50px",

          marginTop: "5px",
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          alt="Logo"
          src="/Cirrom-Logo-Black.webp"
          style={{
            height: "43px",
            width: "135px",
            marginBottom: "-12px",
            marginLeft: "-10px",
            marginRight: "10px",
            marginTop: "2px",
          }}
        />
        <Typography variant={"caption"} style={{ fontWeight: 500 }}>
          A new standard in contact data
        </Typography>
      </div>
    </div>
  );
}
