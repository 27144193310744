import axios from "axios";
import { AuthTokens } from "@aws-amplify/auth";

export interface BatchStatus {
  batch_group: number;
  batch_id: number;
  last_core_window_status: string;
  last_batch_process_name: string;
  last_batch_process_status: string;
  last_batch_process_time: Date;
  last_update_time: Date;
  should_restart: boolean;
}

export interface Report {
  daily_logins: number;
  total_users: number;
  processed_date: string;
  data_projects: { total: number; active: number };
  rules: { total: number; active: number };
  total_changes: number;
  automated_changes: number;
}

export interface ClientStatusResponse {
  id: number;
  name: string;
  batch_statuses: BatchStatus[];
  reports: Report[];
}

export interface ActiveUsers {
  date: string;
  value: number;
}

export const getClientStatus = async (
  credentials: AuthTokens | undefined
): Promise<ClientStatusResponse[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_client_status`,
    {},
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export interface DashboardItem {
  name: string;
  tickets_primary_email: number;
  tickets_first_name: number;
  tickets_last_name: number;
  tickets_company: number;
  tickets_city: number;
  tickets_state: number;
  tickets_country: number;
  tickets_spam_email: number;
  tickets_spam_domain: number;
  tickets_no_mx: number;
  tickets_generic_email: number;
  tickets_bounced_email: number;
  tickets_personal_email: number;
  tickets_dupes: number;
  total_contacts: number;
  total_companies: number;
  total_cirrom_companies: number;
  orphaned_contacts: number;
  tickets_job_title: number;
  score: number;
}

export interface ClientDashboard {
  crm: DashboardItem[];
  projects: DashboardItem[];
}

export const getClientDashboard = async (
  credentials: AuthTokens | undefined,
  clientId: number
): Promise<ClientDashboard> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_dashboard_stats_for_client`,
    { client_id: clientId },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const getMonthlyReport = async (
  credentials: AuthTokens | undefined,
  clientId: number,
  isoDate: string
): Promise<any> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_monthly_report`,
    { client_id: clientId, month: isoDate },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const getActiveUsers = async (
  credentials: AuthTokens | undefined,
  clientId: number,
  isoDate: string
): Promise<ActiveUsers[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_active_logins_report`,
    { client_id: clientId, month: isoDate },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const startInteractionBox = async (
  credentials: AuthTokens | undefined,
  shouldStop?: boolean
): Promise<any> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/start_interaction_box`,
    { stop_instance: shouldStop },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const startNeoBox = async (
  credentials: AuthTokens | undefined,
  shouldStop?: boolean
): Promise<any> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/start_neo_box`,
    { stop_instance: shouldStop },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};
