import React, { useState } from "react";

import { mergeMultipleCompanies } from "../api/companies";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { fetchAuthSession } from "@aws-amplify/auth";

export function MultiCompanyMerge(props: {
  open: boolean;
  onClose: () => void;
}) {
  const [targetCompanyId, setTargetCompanyId] = useState<string>("0");
  const [subordinates, setSubordinates] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();
  const handleCompanyMerge = () => {
    fetchAuthSession().then((credentials) => {
      mergeMultipleCompanies(
        credentials.tokens,
        targetCompanyId,
        subordinates.split(",")
      ).then((response) =>
        enqueueSnackbar("Multi companies merged", {
          variant: "success",
        })
      );
    });

    props.onClose();
  };

  return (
    <Modal
      open={props.open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth={"sm"}>
        <Card>
          <CardHeader
            title={"Merge Multiple Companies"}
            titleTypographyProps={{ variant: "h4" }}
          />
          <Divider />
          <CardContent>
            <TextField
              required
              fullWidth
              variant="outlined"
              size="small"
              margin="dense"
              label={"Target Company Id"}
              value={targetCompanyId}
              onChange={(event) => {
                setTargetCompanyId(event.target.value);
              }}
            />
            <TextField
              required
              fullWidth
              variant="outlined"
              size="small"
              margin="dense"
              label={"Subordinates"}
              value={subordinates}
              onChange={(event) => {
                setSubordinates(event.target.value);
              }}
            />
          </CardContent>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ margin: "2px", padding: "0em 0.5em" }}
              onClick={() => handleCompanyMerge()}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ margin: "2px", padding: "0em 0.5em" }}
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
