import { typography } from "./typography";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    background: {
      default: "rgb(220, 229, 235)",
      paper: "white",
    },
    primary: {
      contrastText: "#ffffff",
      main: "#ffbf23",
    },
    secondary: {
      main: "#d7655d",
    },
    info: {
      main: "#172b4d",
    },
    text: {
      primary: "#172b4d",
      secondary: "rgb(107, 119, 140)",
    },
  },
  typography,
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#ffbf23",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "black",
          "&:hover": {
            color: "white",
            backgroundColor: "#333333",
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          fontSize: "xx-small",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "7px 7px 7px rgb(34 35 58 / 20%)",
          border: "1px solid #d3d3d3",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0px 25px",
        },
      },
    },

    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "12px",
          paddingRight: "12px",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "25px 25px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          flexWrap: "wrap",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#a56403",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          paddingBottom: "8px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#ff4081",
          },
        },
      },
    },
  },
});

/** */
