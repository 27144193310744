import axios from "axios";
import { AuthTokens } from "@aws-amplify/auth";

export interface Company {
  id: number;
  name: string;
  is_inactive: boolean;
  last_update_time: Date;
  model_updates_available: boolean;
  manually_reviewed: boolean;
  model_not_viable: boolean;
}

export interface CompanyDomains {
  id: number;
  domain_name: string;
  domain_extension: string;
  sub_name: string;
  sub_domain: string;
  instance_count: number;
  last_update_time: Date;
  mx_status: string;
  spamhaus_status: string;
  mx_last_updated_time: Date;
  spamhaus_last_updated_time: Date;
  used_in_model_building: boolean;

  whois_last_updated_time: Date;
  whois_org_name: string;
  whois_registrar: string;

  url_last_checked_time: Date;
  end_url: string;
  copyright_footer: string;
  url_header: string;
}

export interface CompanyEndpointResponse {
  total: number;
  companies: Company[];
}

export interface CentralEmailFormatResponse {
  id: number;
  company_id: number;
  active: boolean;
  submit_time: Date;
  source: string;
  model: string;
}

export const fetchCompanies = async (
  credentials: AuthTokens | undefined,
  pageNumber: number,
  companySearchTerm: string,
  domainSearchTerm: string,
  clientSearchTerm: string,
  sortOrder: string | null
): Promise<CompanyEndpointResponse> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_current_companies`,
    {
      page_number: pageNumber,
      company_name: companySearchTerm || null,
      domain_name: domainSearchTerm || null,
      client_name: clientSearchTerm || null,
      sort_field: sortOrder || null,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const fetchDomainsForCompany = async (
  credentials: AuthTokens | undefined,
  companyId: number
): Promise<CompanyDomains[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_domains_for_company`,
    { company_id: companyId },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const extractIncorrectCompany = async (
  credentials: AuthTokens | undefined,
  newCompanyName: string,
  companyId: number,
  affectedDomainIds: number[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/extract_incorrect_company`,
    {
      company_id: companyId,
      new_company_name: newCompanyName,
      incorrect_domain_ids: affectedDomainIds,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const fetchCentralEmailFormatForCompany = async (
  credentials: AuthTokens | undefined,
  companyId: number
): Promise<CentralEmailFormatResponse[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_email_format_for_company`,
    { company_id: companyId },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const moveCompanyDomains = async (
  credentials: AuthTokens | undefined,
  companyId: number,
  domainIds: number[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/move_company_domains`,
    {
      company_id: companyId,
      domain_ids: domainIds,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const editEmailFormat = async (
  credentials: AuthTokens | undefined,
  model_format_id: number,
  model_format: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/edit_email_format`,
    {
      model_format_id: model_format_id,
      model_format: model_format,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const removeDomainsFromModel = async (
  credentials: AuthTokens | undefined,
  domainIds: number[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/remove_domains_from_models`,
    {
      domain_ids: domainIds,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const markDomainsAsTypo = async (
  credentials: AuthTokens | undefined,
  domainIds: number[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/mark_domains_as_typo`,
    {
      domain_ids: domainIds,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const markCompanyAsModelNotViable = async (
  credentials: AuthTokens | undefined,
  companyId: number
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/flag_model_as_not_viable`,
    {
      company_id: companyId,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const markCompanyAsReviewed = async (
  credentials: AuthTokens | undefined,
  companyId: number
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/mark_company_as_reviewed`,
    {
      company_id: companyId,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const mergeMultipleCompanies = async (
  credentials: AuthTokens | undefined,
  targetCompanyId: string,
  subordinates: string[]
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/merge_companies`,
    {
      target_company_id: targetCompanyId,
      subordinate_company_ids: subordinates,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );

  return response.data;
};

export const getCompanyNameClashes = async (
  credentials: AuthTokens | undefined
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_company_name_clashes`,
    {},
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};
