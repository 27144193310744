import React, { useEffect, useState } from "react";
import {
  CirromClient,
  getAvailableClients,
  triggerClientReinstall,
  triggerClientUiReinstall,
} from "../api/releases";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { fetchAuthSession } from "@aws-amplify/auth";

function ClientView(props: { client: CirromClient }) {
  const [clientVersion, setClientVersion] = useState<string>(
    props.client.client_target_version
  );

  const [uiDeploymentUrl, setUiDeploymentUrl] = useState<string>(
    props.client.client_ui_deployment_url || ""
  );
  const [wsDeploymentUrl, setWsDeploymentUrl] = useState<string>(
    props.client.client_ui_deployment_ws_url || ""
  );
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <div style={{ marginTop: "10px" }}>
      <Typography
        variant={"h5"}
      >{`${props.client.id}) ${props.client.instance_name}(${props.client.platform}) ----> Last Update: ${props.client.last_update_time} `}</Typography>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <TextField
          variant={"outlined"}
          margin="dense"
          size={"small"}
          style={{ margin: "2px", padding: "2px" }}
          value={clientVersion}
          label={"Client Version"}
          onChange={(event) => {
            setClientVersion(event.target.value);
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            margin: "2px",
            padding: "0em 0.5em",
            textTransform: "capitalize",
            height: "30px",
          }}
          onClick={() => {
            setRequestInProgress(true);
            fetchAuthSession().then((credentials) => {
              triggerClientReinstall(
                credentials.tokens,
                props.client.id,
                clientVersion
              ).then(() => {
                enqueueSnackbar("Release requested", { variant: "success" });
                setRequestInProgress(false);
              });
            });
          }}
        >
          Reinstall Cirrom
        </Button>
        <TextField
          size={"small"}
          style={{ margin: "2px", padding: "2px" }}
          value={uiDeploymentUrl}
          onChange={(event) => {
            setUiDeploymentUrl(event.target.value);
          }}
          label={"Ui Deployment URL"}
        />
        <TextField
          size={"small"}
          style={{ margin: "2px", padding: "2px" }}
          label={"WS Deployment URL"}
          inputProps={{ margin: "0px", padding: "0px" }}
          value={wsDeploymentUrl}
          onChange={(event) => {
            setWsDeploymentUrl(event.target.value);
          }}
        />

        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            margin: "2px",
            padding: "0em 0.5em",
            textTransform: "capitalize",
            height: "30px",
          }}
          onClick={() => {
            setRequestInProgress(true);
            fetchAuthSession().then((credentials) => {
              triggerClientUiReinstall(
                credentials.tokens,
                props.client.id,
                uiDeploymentUrl,
                wsDeploymentUrl
              ).then(() => {
                enqueueSnackbar("Release requested", { variant: "success" });
                setRequestInProgress(false);
              });
            });
          }}
        >
          Reinstall UI
        </Button>
      </div>
      {requestInProgress && <LinearProgress variant={"indeterminate"} />}
      <Divider />
    </div>
  );
}

export function Releases() {
  const [fetchedClients, setFetchedClients] = useState<boolean>(false);
  const [clients, setClients] = useState<CirromClient[]>([]);

  useEffect(() => {
    let isMounted = true;
    fetchAuthSession().then((credentials) => {
      getAvailableClients(credentials.tokens).then((response) => {
        if (isMounted) {
          setFetchedClients(true);
          setClients(response);
        }
      });
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Card>
      <CardHeader title={"Releases"} titleTypographyProps={{ variant: "h3" }} />
      <CardContent>
        <Divider />
        {!fetchedClients && <LinearProgress variant={"indeterminate"} />}
        <div style={{ marginTop: "20px" }}>
          {clients.map((client) => {
            return <ClientView key={client.id} client={client} />;
          })}
        </div>
      </CardContent>
    </Card>
  );
}
