import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";
import CardContent from "@mui/material/CardContent";
import {
  ClientStatusResponse,
  getClientStatus,
  Report,
  startInteractionBox,
  startNeoBox,
} from "../api/reports";
import { AgGridReact } from "ag-grid-react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { fetchAuthSession } from "@aws-amplify/auth";
import { ResponsiveBar } from "@nivo/bar";

const getWeekdayLoginAverage = (reports: Report[]) => {
  let validEntries: number[] = [];
  reports.forEach((report) => {
    const reportDate = new Date(report.processed_date);
    if (reportDate.getDay() % 6) {
      validEntries.push(report.daily_logins);
    }
  });
  if (validEntries.length) {
    return validEntries.reduce((a, b) => a + b, 0) / validEntries.length;
  }
  return 0;
};

export function Reports() {
  const [fetchedContent, setFetchedContent] = useState<boolean>(false);
  const [clientStatusResponse, setClientStatusResponse] = useState<
    ClientStatusResponse[]
  >([]);
  const { enqueueSnackbar } = useSnackbar();

  const gridColumns = [
    {
      field: "batch_group",
      headerName: "Batch Group",
      resizable: true,
    },
    {
      field: "batch_id",
      headerName: "Batch ID",
      resizable: true,
    },
    {
      field: "last_core_window_status",
      headerName: "Core Window Status",
      resizable: true,
    },
    {
      field: "last_batch_process_name",
      headerName: "Batch Process Name",
      resizable: true,
    },
    {
      field: "last_batch_process_status",
      headerName: "Batch Process Status",
      resizable: true,
    },
    {
      field: "last_batch_process_time",
      headerName: "Batch Process Time",
      resizable: true,
    },
    {
      field: "last_update_time",
      headerName: "Last Update Time",
      resizable: true,
    },
  ];

  useEffect(() => {
    let isMounted = true;
    setFetchedContent(false);
    fetchAuthSession().then((credentials) => {
      getClientStatus(credentials.tokens).then((response) => {
        if (isMounted) {
          setFetchedContent(true);
          setClientStatusResponse(response);
        }
      });
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Card style={{ height: "100%" }}>
      <div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            margin: "2px",
            padding: "0em 0.5em",
          }}
          onClick={() => {
            fetchAuthSession().then((credentials) => {
              startInteractionBox(credentials.tokens).then((response) => {
                enqueueSnackbar("InterAction started", {
                  variant: "success",
                });
              });
            });
          }}
        >
          Start InterAction
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            margin: "2px",
            padding: "0em 0.5em",
            backgroundColor: "#b71c1c",
            color: "#F5F5F5",
          }}
          onClick={() => {
            fetchAuthSession().then((credentials) => {
              startInteractionBox(credentials.tokens, true).then((response) => {
                enqueueSnackbar("InterAction stopped", {
                  variant: "success",
                });
              });
            });
          }}
        >
          Stop InterAction
        </Button>

        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ margin: "2px", padding: "0em 0.5em" }}
          onClick={() => {
            fetchAuthSession().then((credentials) => {
              startNeoBox(credentials.tokens).then((response) => {
                enqueueSnackbar("Neo started", {
                  variant: "success",
                });
              });
            });
          }}
        >
          Start Neo
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            margin: "2px",
            padding: "0em 0.5em",
            backgroundColor: "#b71c1c",
            color: "#F5F5F5",
          }}
          onClick={() => {
            fetchAuthSession().then((credentials) => {
              startNeoBox(credentials.tokens, true).then((response) => {
                enqueueSnackbar("Neo stopped", {
                  variant: "success",
                });
              });
            });
          }}
        >
          Stop Neo
        </Button>
      </div>
      <CardHeader title={"Reports"} titleTypographyProps={{ variant: "h3" }} />
      <CardContent style={{ height: "100%" }}>
        <Divider />
        {!fetchedContent && <LinearProgress variant={"indeterminate"} />}
        <div
          style={{
            overflow: "scroll",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {clientStatusResponse &&
            clientStatusResponse.map((clientStatus) => {
              return (
                <div key={clientStatus.id} style={{ width: "100%" }}>
                  <Typography
                    variant={"h5"}
                    style={{ marginTop: "5px", marginBottom: "5px" }}
                  >
                    {clientStatus.name}
                  </Typography>
                  <div
                    style={{ height: "550px", display: "flex" }}
                    className="ag-theme-alpine"
                  >
                    <div style={{ height: "90%", width: "49%" }}>
                      <AgGridReact
                        rowData={clientStatus.batch_statuses}
                        columnDefs={gridColumns}
                        rowClassRules={{
                          "row-fail": (params) => {
                            return (
                              params?.data?.last_batch_process_status ===
                                "BATCH_ERROR" ?? false
                            );
                          },
                          blink: (params) => {
                            const twentyFourHrsAgo = new Date();
                            twentyFourHrsAgo.setHours(
                              twentyFourHrsAgo.getHours() - 24
                            );

                            return !!(
                              params.rowIndex === 0 &&
                              params?.data?.last_batch_process_time &&
                              new Date(params?.data?.last_batch_process_time) <=
                                twentyFourHrsAgo
                            );
                          },
                        }}
                      />
                    </div>
                    <div style={{ height: "100%", width: "49%" }}>
                      <Typography
                        variant={"caption"}
                        style={{
                          paddingLeft: "10px",
                          color:
                            getWeekdayLoginAverage(clientStatus.reports) > 1
                              ? "#037956"
                              : "#b71c1c",
                        }}
                      >{`Daily avg. users: ${getWeekdayLoginAverage(
                        clientStatus.reports
                      )}`}</Typography>
                      <div style={{ height: "125px" }}>
                        <ResponsiveBar
                          data={clientStatus.reports
                            .map((report) => {
                              return {
                                dailyLogins: report.daily_logins || 0,
                                totalUsers: report.total_users || 0,
                                readableDay: new Date(report.processed_date)
                                  .toLocaleDateString("en-GB")
                                  .slice(0, 5),
                              };
                            })
                            .reverse()}
                          groupMode={"grouped"}
                          keys={["dailyLogins", "totalUsers"]}
                          indexBy="readableDay"
                          role="application"
                          margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
                          padding={0.15}
                          enableGridY={false}
                          labelSkipHeight={2}
                          valueScale={{ type: "linear" }}
                          indexScale={{ type: "band", round: true }}
                          colors={{ scheme: "accent" }}
                          borderRadius={2}
                          axisLeft={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "Unique User Logins / Total Users",
                            legendPosition: "middle",
                            legendOffset: 32,
                            truncateTickAt: 0,
                          }}
                        />
                      </div>
                      <div style={{ height: "125px" }}>
                        <ResponsiveBar
                          data={clientStatus.reports
                            .map((report) => {
                              return {
                                active: report?.data_projects?.active ?? 0,
                                inactive:
                                  (report?.data_projects?.total ?? 0) -
                                  (report?.data_projects?.active ?? 0),
                                readableDay: new Date(report.processed_date)
                                  .toLocaleDateString("en-GB")
                                  .slice(0, 5),
                              };
                            })
                            .reverse()}
                          groupMode={"grouped"}
                          keys={["active", "inactive"]}
                          indexBy="readableDay"
                          role="application"
                          margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
                          padding={0.15}
                          enableGridY={false}
                          labelSkipHeight={2}
                          valueScale={{ type: "linear" }}
                          indexScale={{ type: "band", round: true }}
                          colors={{ scheme: "dark2" }}
                          borderRadius={2}
                          axisLeft={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "Data Projects",
                            legendPosition: "middle",
                            legendOffset: 32,
                            truncateTickAt: 0,
                          }}
                        />
                      </div>
                      <div style={{ height: "125px" }}>
                        <ResponsiveBar
                          data={clientStatus.reports
                            .map((report) => {
                              return {
                                active: report?.rules?.active ?? 0,
                                inactive:
                                  (report?.rules?.total ?? 0) -
                                  (report?.rules?.active ?? 0),
                                readableDay: new Date(report.processed_date)
                                  .toLocaleDateString("en-GB")
                                  .slice(0, 5),
                              };
                            })
                            .reverse()}
                          groupMode={"grouped"}
                          keys={["active", "inactive"]}
                          indexBy="readableDay"
                          role="application"
                          margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
                          padding={0.15}
                          enableGridY={false}
                          labelSkipHeight={2}
                          valueScale={{ type: "linear" }}
                          indexScale={{ type: "band", round: true }}
                          colors={{ scheme: "dark2" }}
                          borderRadius={2}
                          axisLeft={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "Rules",
                            legendPosition: "middle",
                            legendOffset: 32,
                            truncateTickAt: 0,
                          }}
                        />
                      </div>
                      <div style={{ height: "125px" }}>
                        <ResponsiveBar
                          data={clientStatus.reports
                            .map((report) => {
                              return {
                                total: report.total_changes,
                                automated: report.automated_changes,
                                readableDay: new Date(report.processed_date)
                                  .toLocaleDateString("en-GB")
                                  .slice(0, 5),
                              };
                            })
                            .reverse()}
                          groupMode={"grouped"}
                          keys={["total", "automated"]}
                          indexBy="readableDay"
                          role="application"
                          margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
                          padding={0.15}
                          enableGridY={false}
                          labelSkipHeight={2}
                          valueScale={{ type: "linear" }}
                          indexScale={{ type: "band", round: true }}
                          colors={{ scheme: "dark2" }}
                          borderRadius={2}
                          axisLeft={null}
                          axisRight={null}
                          axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: "MTD Changes",
                            legendPosition: "middle",
                            legendOffset: 32,
                            truncateTickAt: 0,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </CardContent>
    </Card>
  );
}
