import React from "react";

import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { updateCompanySICInformation } from "../api/metadata";
import { fetchAuthSession } from "@aws-amplify/auth";

export function UpdateCompanySIC(props: {
  open: boolean;
  onClose: () => void;
  selectedCompanyId: number;
}) {
  const [companyIdentifier, setCompanyIdentifier] = React.useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const handleUpdate = () => {
    fetchAuthSession().then((response) => {
      updateCompanySICInformation(
        response.tokens,
        props.selectedCompanyId,
        companyIdentifier
      ).then((response) =>
        enqueueSnackbar("SIC information updated.", {
          variant: "success",
        })
      );
    });

    props.onClose();
  };

  return (
    <Modal
      open={props.open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth={"sm"}>
        <Card>
          <CardHeader
            title={"Update SIC"}
            titleTypographyProps={{ variant: "h4" }}
          />
          <Divider />
          <CardContent>
            <TextField
              required
              fullWidth
              variant="outlined"
              size="small"
              margin="dense"
              label={"SIC Identifier"}
              value={companyIdentifier}
              onChange={(event) => {
                setCompanyIdentifier(event.target.value);
              }}
            />
          </CardContent>
          <CardContent
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ margin: "2px", padding: "0em 0.5em" }}
              onClick={() => handleUpdate()}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ margin: "2px", padding: "0em 0.5em" }}
              onClick={props.onClose}
            >
              Cancel
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
