import CardHeader from "@mui/material/CardHeader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  ActiveUsers,
  DashboardItem,
  getActiveUsers,
  getClientDashboard,
  getMonthlyReport,
} from "../api/reports";
import TextField from "@mui/material/TextField";
import { NewCRMPanel } from "./NewCRMPanel";
import {
  TabContext,
  TabPanel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/lab";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { green, grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import DownloadIcon from "@mui/icons-material/Download";
import LinkIcon from "@mui/icons-material/Link";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import BadgeIcon from "@mui/icons-material/Badge";
import WorkIcon from "@mui/icons-material/Work";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import EngineeringIcon from "@mui/icons-material/Engineering";
import VerifiedIcon from "@mui/icons-material/Verified";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { fetchAuthSession } from "@aws-amplify/auth";
import LinearProgress from "@mui/material/LinearProgress";
import { toPng } from "html-to-image";
import { CirromLogo } from "./CirromLogo";
import { CirromClient } from "../api/releases";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { ResponsiveCalendar } from "@nivo/calendar";

export const getCrmScoreStyles = (score: number) => {
  if (score < 35) {
    return { background: "#e50144" };
  }
  if (score < 45) {
    return { background: "#e65100" };
  }
  if (score < 55) {
    return { background: "#f57c00" };
  }
  if (score < 65) {
    return { background: "#ffa000" };
  }
  if (score < 77) {
    return { background: "#fbc02d" };
  }
  if (score < 85) {
    return { background: "#b5c332" };
  }
  if (score < 92) {
    return { background: "#8bc34a" };
  }
  if (score >= 92) {
    return { background: "#34be5d" };
  }
  return { background: "#e50144" };
};

const HighLevelStat = (props: { stat: any; title: string; icon: any }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {props.icon}

      <Typography variant={"h4"} fontWeight={"bold"}>
        {props.title}
      </Typography>
      <Typography
        variant={"h3"}
        style={{ fontWeight: "bold", color: green["500"] }}
      >
        {new Intl.NumberFormat("en-US").format(props.stat)}
      </Typography>
    </div>
  );
};

const AttributeStat = (props: { stat: any; title: string; icon: any }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "32%",
        marginBottom: "15px",
      }}
    >
      {props.icon}

      <Typography variant={"h5"} fontWeight={"bold"}>
        {props.title}
      </Typography>
      <Typography
        variant={"h4"}
        style={{ fontWeight: "bold", color: green["500"] }}
      >
        {new Intl.NumberFormat("en-US").format(props.stat)}
      </Typography>
    </div>
  );
};

const MonthlyReport = (props: {
  report: any;
  scoreStart: number;
  scoreLatest: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const onExport = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `report.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const { report } = props;

  return (
    <div style={{ padding: "20px" }}>
      <div>
        <IconButton onClick={() => onExport()}>
          <DownloadIcon />
        </IconButton>
      </div>
      <div ref={ref}>
        <div style={{ background: "#ffbf23", padding: "40px" }}>
          <Typography variant={"h2"} fontWeight={"bold"} gutterBottom>
            Your new standard in contact data!
          </Typography>
          <Typography variant={"h5"}>
            Check out the cool stuff that's been happening in your
          </Typography>
          <Typography variant={"h5"}>{`CRM data in ${new Date(
            report.date
          ).toLocaleString("default", {
            month: "long",
          })}`}</Typography>
        </div>
        <div>
          <Divider style={{ background: "black", height: "1px" }} />
        </div>
        <div style={{ padding: "20px", background: "white" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "20px",
            }}
          >
            <HighLevelStat
              stat={report?.total_changes}
              title={"Total Changes"}
              icon={
                <VerifiedIcon
                  style={{ width: "64px", height: "64px", color: "#ffbf23" }}
                />
              }
            />
            <HighLevelStat
              stat={report?.contact_changes}
              title={"Contact Changes"}
              icon={
                <RecentActorsIcon
                  style={{ width: "64px", height: "64px", color: "#ffbf23" }}
                />
              }
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <HighLevelStat
              stat={report?.account_changes}
              title={"Account Changes"}
              icon={
                <PersonIcon
                  style={{ width: "64px", height: "64px", color: "#ffbf23" }}
                />
              }
            />

            <HighLevelStat
              stat={report?.automated_changes}
              title={"Automated Changes"}
              icon={
                <EngineeringIcon
                  style={{ width: "64px", height: "64px", color: "#ffbf23" }}
                />
              }
            />
          </div>
        </div>
        <div>
          <Divider style={{ background: "black", height: "1px" }} />
        </div>
        <div style={{ padding: "25px", background: grey["300"] }}>
          <div
            style={{
              display: "flex",
              gap: "2px",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Typography variant={"h5"} fontWeight={"bold"}>
              Data Fixes
            </Typography>
            <Typography variant={"h5"}>
              - Contact data fixes across key fields...
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "10px",
              flexWrap: "wrap",
            }}
          >
            <AttributeStat
              stat={report?.city}
              title={"City"}
              icon={
                <LocationCityIcon
                  style={{
                    width: "48px",
                    height: "48px",
                    color: "black",
                  }}
                />
              }
            />
            <AttributeStat
              stat={report?.company_linkage}
              title={"Company Linkage"}
              icon={
                <LinkIcon
                  style={{
                    width: "48px",
                    height: "48px",
                    color: "black",
                  }}
                />
              }
            />
            <AttributeStat
              stat={report?.country}
              title={"Country"}
              icon={
                <PublicIcon
                  style={{
                    width: "48px",
                    height: "48px",
                    color: "black",
                  }}
                />
              }
            />
            <AttributeStat
              stat={report?.name}
              title={"Name"}
              icon={
                <BadgeIcon
                  style={{
                    width: "48px",
                    height: "48px",
                    color: "black",
                  }}
                />
              }
            />
            <AttributeStat
              stat={report?.job_title}
              title={"Job Title"}
              icon={
                <WorkIcon
                  style={{
                    width: "48px",
                    height: "48px",
                    color: "black",
                  }}
                />
              }
            />
            <AttributeStat
              stat={report?.email}
              title={"Email"}
              icon={
                <MailOutlineIcon
                  style={{
                    width: "48px",
                    height: "48px",
                    color: "black",
                  }}
                />
              }
            />
          </div>
        </div>
        <div>
          <Divider style={{ background: "black", height: "1px" }} />
        </div>
        <div
          style={{
            padding: "20px",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
            }}
          >
            <Typography
              variant="h4"
              fontWeight={"bold"}
              style={{ marginTop: "25px" }}
            >
              CRM Health Score
            </Typography>
            <div
              style={{
                width: "150px",
                textAlign: "center",
              }}
            >
              <Typography
                variant={"h5"}
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Start
              </Typography>
              <Typography
                variant={"h3"}
                style={{
                  color: "white",
                  padding: "10px 10px",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  ...getCrmScoreStyles(props?.scoreStart ?? 0),
                }}
              >
                {props.scoreStart}%
              </Typography>
            </div>
            <div
              style={{
                width: "150px",
                textAlign: "center",
              }}
            >
              <Typography
                variant={"h5"}
                style={{ fontWeight: "bold" }}
                gutterBottom
              >
                Latest
              </Typography>
              <Typography
                variant={"h3"}
                style={{
                  color: "white",
                  padding: "10px 10px",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  ...getCrmScoreStyles(props?.scoreStart ?? 0),
                }}
              >
                {props.scoreStart}%
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <AccessTimeIcon
              style={{ color: "#ffbf23", height: "64px", width: "64px" }}
            />
            <Typography variant="h5" fontWeight={"bold"}>
              Time Saved (based on 3 minutes per change)
            </Typography>
            <Typography
              variant={"h4"}
              fontWeight={"bold"}
              style={{ color: green["500"] }}
            >
              {`${new Intl.NumberFormat("en-US").format(
                //@ts-ignore
                Math.floor((report?.total_changes * 3) / 60)
              )} Hours`}
            </Typography>
          </div>
        </div>
        <div>
          <Divider style={{ background: "black", height: "1px" }} />
        </div>
        <div style={{ padding: "20px", background: "white" }}>
          <CirromLogo />
        </div>
      </div>
    </div>
  );
};

export function Dashboards(props: { clients: CirromClient[] }) {
  const [clientId, setClientId] = useState<number>();
  const [selectedYear, setSelectedYear] = useState<string>("2024");
  const [selectedMonth, setSelectedMonth] = useState<string>(
    String(new Date().getMonth() + 1).padStart(2, "0")
  );
  const [clientDashboards, setClientDashboards] = useState<DashboardItem[]>();
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [latestMonthlyReport, setLatestMonthlyReport] = useState<any>(null);
  const [selectedView, setSelectedView] = useState<string>("report");
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [scoreStart, setScoreStart] = useState<number>();
  const [scoreLatest, setScoreLatest] = useState<number>();
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [activeUsers, setActiveUsers] = useState<ActiveUsers[]>([]);

  useEffect(() => {
    if (clientId) {
      setIsFetchingData(true);
      fetchAuthSession().then((credentials) => {
        getMonthlyReport(
          credentials.tokens,
          clientId,
          `${selectedYear}-${selectedMonth}-01`
        ).then((response) => {
          setLatestMonthlyReport(response?.report);
          setScoreStart(response?.scoreStart);
          setScoreLatest(response?.scoreLatest);
          setIsFetchingData(false);
        });
        getActiveUsers(
          credentials.tokens,
          clientId,
          `${selectedYear}-${selectedMonth}-01`
        ).then((response) => setActiveUsers(response));
        getClientDashboard(credentials.tokens, clientId).then((response) => {
          const localDashboards: DashboardItem[] = [];

          response.crm.forEach((item) => {
            if (item.name) {
              localDashboards.push(item);
            }
          });
          response.projects.forEach((item) => {
            if (item.name) {
              localDashboards.push(item);
            }
          });
          setClientDashboards(localDashboards);
          if (response && response.crm && response.crm.length > 0) {
            setSelectedTab(response.crm[0].name);
          }
        });
      });
    }
  }, [clientId, selectedMonth, selectedYear, fetchData]);

  return (
    <Card>
      <CardHeader
        title={"Dashboards"}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent style={{ height: "1350px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "5px", alignItems: "baseline" }}>
            <TextField
              margin={"dense"}
              size={"small"}
              variant={"standard"}
              label={"Client Id"}
              onChange={(event) => {
                setClientId(parseInt(event.target.value));
              }}
              select
              style={{ width: "250px" }}
            >
              {props.clients
                .sort((first, second) => {
                  if (first.instance_name < second.instance_name) {
                    return -1;
                  }
                  if (first.instance_name > second.instance_name) {
                    return 1;
                  }
                  return 0;
                })
                .map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.instance_name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              select
              margin={"dense"}
              size={"small"}
              variant={"standard"}
              label={"Month"}
              value={selectedMonth}
              onChange={(event) => setSelectedMonth(event.target.value)}
            >
              <MenuItem value={"01"}>Jan</MenuItem>
              <MenuItem value={"02"}>Feb</MenuItem>
              <MenuItem value={"03"}>Mar</MenuItem>
              <MenuItem value={"04"}>Apr</MenuItem>
              <MenuItem value={"05"}>May</MenuItem>
              <MenuItem value={"06"}>Jun</MenuItem>
              <MenuItem value={"07"}>Jul</MenuItem>
              <MenuItem value={"08"}>Aug</MenuItem>
              <MenuItem value={"09"}>Sep</MenuItem>
              <MenuItem value={"10"}>Oct</MenuItem>
              <MenuItem value={"11"}>Nov</MenuItem>
              <MenuItem value={"12"}>Dec</MenuItem>
            </TextField>
            <TextField
              select
              margin={"dense"}
              size={"small"}
              variant={"standard"}
              label={"Year"}
              value={selectedYear}
              onChange={(event) => setSelectedYear(event.target.value)}
            >
              <MenuItem value={"2024"}>2024</MenuItem>
              <MenuItem value={"2025"}>2025</MenuItem>
              <MenuItem value={"2026"}>2026</MenuItem>
              <MenuItem value={"2027"}>2027</MenuItem>
            </TextField>
            <Button
              variant={"contained"}
              style={{ width: "20px", height: "20px" }}
              onClick={() => setFetchData(!fetchData)}
            >
              Go
            </Button>
          </div>

          <ToggleButtonGroup
            exclusive
            onChange={(event) => {
              // @ts-ignore
              setSelectedView(event.target.value);
            }}
            aria-label="text alignment"
            style={{ height: "30px" }}
            value={selectedView}
          >
            <ToggleButton value="report" aria-label="left aligned">
              Reports
            </ToggleButton>
            <ToggleButton value="dashboard" aria-label="centered">
              Dashboards
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {isFetchingData && <LinearProgress variant={"indeterminate"} />}

        {selectedView === "report" && latestMonthlyReport && (
          <>
            <div style={{ height: "200px" }}>
              <ResponsiveCalendar
                data={activeUsers.map((entry) => {
                  return {
                    day: entry.date.slice(0, 10),
                    value: entry.value,
                  };
                })}
                from={new Date(new Date().setDate(new Date().getDate() - 14))
                  .toISOString()
                  .slice(0, 10)}
                to={new Date().toISOString().slice(0, 10)}
                emptyColor="#eeeeee"
                colors={["#f47560", "#e8c1a0", "#61cdbb", "#97e3d5"]}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
              />
            </div>
            <MonthlyReport
              report={latestMonthlyReport}
              scoreStart={scoreStart || 0}
              scoreLatest={scoreLatest || 0}
            />
          </>
        )}
        {selectedView === "dashboard" && clientDashboards && (
          <TabContext value={selectedTab}>
            <Tabs
              value={selectedTab}
              onChange={(event, val) => {
                setSelectedTab(val);
              }}
            >
              {clientDashboards.map((item) => (
                <Tab label={item.name} value={item.name} key={item.name} />
              ))}
            </Tabs>
            {clientDashboards.map((item) => (
              <TabPanel value={item.name} key={item.name}>
                <NewCRMPanel stats={item} />
              </TabPanel>
            ))}
          </TabContext>
        )}
      </CardContent>
    </Card>
  );
}
