import axios from "axios";
import { AuthTokens } from "@aws-amplify/auth";

export interface CirromClient {
  id: number;
  instance_name: string;
  active: boolean;
  client_target_version: string;
  client_ui_target_version: string;
  client_ui_deployment_url: string;
  client_ui_deployment_ws_url: string;
  last_update_time: Date;
  platform: string;
}

export const getAvailableClients = async (
  credentials: AuthTokens | undefined
): Promise<CirromClient[]> => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/get_current_clients`,
    {},
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const triggerClientReinstall = async (
  credentials: AuthTokens | undefined,
  clientId: number,
  cirromClientVersion: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/trigger_client_reinstall`,
    { client_id: clientId, cirrom_client_version: cirromClientVersion },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};

export const triggerClientUiReinstall = async (
  credentials: AuthTokens | undefined,
  clientId: number,
  uiDeploymentUrl: string,
  wsDeploymentUrl: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_AWS_URL}/trigger_client_ui_reinstall`,
    {
      client_id: clientId,
      client_ui_deployment_url: uiDeploymentUrl,
      client_ui_deployment_ws_url: wsDeploymentUrl,
    },
    { headers: { Authorization: credentials?.idToken?.toString() } }
  );
  return response.data;
};
